import React, { useEffect } from 'react'

function retryInstant(query, timout) {
  try {
    query()
  } catch (err) {
    if (!window.location.href.includes('uk')) {
      setTimeout(() => retryInstant(query, timout), timout)
    }
  }
}

function retryLearnQ(query, timout) {
  try {
    query()
  } catch (err) {
    if (!window.location.href.includes('uk')) {
      setTimeout(() => retryLearnQ(query, timout), timout)
    }
  }
}

const InstantCollectionTracking = ({ data }) => {
  useEffect(() => {
    retryInstant(() => {
      const collection = {
        CollectionName: data.CollectionName,
        CollectionURL: window.location.href,
      }

      window.InstantJS.track('COLLECTION_VIEWED', [
        {
          provider: 'KLAVIYO',
          event: collection,
        },
      ])
    })

    var _learnq = window._learnq || []

    retryLearnQ(() => {
      const collection = {
        CollectionName: data.CollectionName,
        CollectionURL: window.location.href,
      }

      _learnq.push(['track', 'Viewed Collection', collection])
    }, 3000)
  }, [data.CollectionName])
  return <></>
}

export default InstantCollectionTracking
