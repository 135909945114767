import styled from '@emotion/styled'
import React, { useContext, useEffect, useRef } from 'react'
import { CartContext } from '../../../context/shopContext'

const ReviewContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 32px;
  max-width: 100vw;
  @media (max-width: 600px) {
    padding: 0;
    max-width: 350px;

  }
`
const ExploreHeaderContainer = styled.div`
  padding: 40px 0 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: 1025px) {
    margin: 20px 0;
  }
`
const ExploreHeading = styled.h2`
  font-size: 36px;
  font-weight: 400;
  line-height: 1.1;
  letter-spacing: -1.17px;
  text-align: center;
  color: #000;
`
const OkendoReviewsWidget = ({ productId, copy }) => {
  const widgetContainer = useRef(null)
  const initialiseReviewsWidget = () => {
    if (widgetContainer?.current) {
      window?.okeWidgetApi?.initWidget(widgetContainer.current)
    }
  }

  useEffect(() => {
    if (window.scrollLoaded) {
      initialiseReviewsWidget()
    } else {
      window.addEventListener('windowScrolled', initialiseReviewsWidget)
    }
  }, [])

  return (
    <ReviewContainer>
      <ExploreHeaderContainer>
        <ExploreHeading> {copy} </ExploreHeading>
      </ExploreHeaderContainer>
      <div
        ref={widgetContainer}
        data-oke-carousel
        data-oke-reviews-product-id={`shopify-${productId}`}
        data-oke-reviews-min-images="1"
      ></div>
      <br />
    </ReviewContainer>
  )
}

const ReviewsContainer = styled.div`
  .oke-reviewCarousel-header {
    display: none;
  }

  @media (max-width: 1025px) {
    flex-direction: column;
    align-items: center;
  }
`
const WarrantyContainer = styled.div`
  background-color: white;
  padding-bottom: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  @media (max-width: 1025px) {
    flex-direction: column;
    align-items: center;
  }
`
const WarrantySection = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
  align-items: center;
  width: 100%; /* Ensures full width on smaller screens */
  @media (max-width: 1025px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 100%;
  }
`

const SmallReviewBanner = ({ data, locale }) => {
  const storeLocale = locale
  return (
    <WarrantyContainer>
      <WarrantySection>
        <ReviewsContainer>
          <OkendoReviewsWidget productId={data?.ReviewID ? data?.ReviewID : (storeLocale === 'en-NZ' ? 8486902071608 : storeLocale === 'en' ? 6776628609127 : storeLocale === 'en-GB' ? 7991445094588 : 6546329534521)} copy={data.Copy} />
        </ReviewsContainer>
      </WarrantySection>
    </WarrantyContainer>
  )
}

export default SmallReviewBanner
