import React, {useState, useEffect, useRef, useContext} from 'react';
import { CartContext } from '../../context/shopContext';

const loadScript = () => {
  var n = document.createElement("script");
  n.type = "text/javascript";
  n.async = !0;
  n.src = '/referrals-api.js';
  var a = document.getElementsByTagName("script")[0];
  a.parentNode.insertBefore(n, a);  
}

const injectMetaData = (metadata) => {
  const scriptTag = document.getElementById('okeReferralSettings');
    
  if (scriptTag) {
    // Parse the existing JSON data
    const existingData = JSON.parse(scriptTag.textContent || scriptTag.innerText);

    // Modify the data (here I'm adding a new key-value pair as an example)
    const updatedData = { ...existingData, ...metadata };

    // Update the content of the script tag with the new JSON data
    scriptTag.textContent = JSON.stringify(updatedData);
  }
}

async function createMetafieldStorefrontVisibility() {
  let domain = process.env.SHOPIFY_STORE_DOMAIN
  let storefrontAccessToken = process.env.SHOPIFY_STOREFRONT_ACCESSTOKEN
  if (localStorage.locale === 'en') {
    domain = process.env.US_SHOPIFY_STORE_DOMAIN
    storefrontAccessToken = process.env.US_SHOPIFY_STOREFRONT_ACCESSTOKEN
  } else if (localStorage.locale === 'en-NZ') {
    domain = process.env.NZ_SHOPIFY_STORE_DOMAIN
    storefrontAccessToken = process.env.NZ_SHOPIFY_STOREFRONT_ACCESSTOKEN
  } else if (localStorage.locale === 'en-GB') {
    domain = process.env.UK_SHOPIFY_STORE_DOMAIN
    storefrontAccessToken = process.env.UK_SHOPIFY_STOREFRONT_ACCESSTOKEN
  }

  const url = `https://${domain}.myshopify.com/admin/api/2022-04/graphql.json`;
  
  const query = `
    mutation {
      metafieldStorefrontVisibilityCreate(
        input: {
          namespace: "okendo"
          key: "ReferralSettings"
          ownerType: SHOP
        }
      ) {
        metafieldStorefrontVisibility {
          id
        }
        userErrors {
          field
          message
        }
      }
    }
  `;

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/graphql',
      'X-Shopify-Access-Token': storefrontAccessToken,
    },
    body: query,
  };

  try {
    const response = await fetch(url, options);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error);
  }
}

const OkendoReferals = () => {
  const { setDiscountCode } = useContext(CartContext)
  let observer = useRef()
  const [loaded, setLoaded] = useState(false)
  const [discount, setDiscount] = useState()
  useEffect(async () => {
    if (!loaded) {
      setLoaded(true)
      const metaData = await createMetafieldStorefrontVisibility()
      injectMetaData(metaData)
      loadScript()

      observer = new MutationObserver((mutationsList, observer) => {
        for(let mutation of mutationsList) {
            if (mutation.type === 'childList') {
                const element = document.querySelector('.c-referralRecipientThankyou-coupon input#shareableLink');
                if(element && element?.value) {
                    setDiscount(element?.value)
                    setDiscountCode(element?.value)
                    observer.disconnect();
                }
            }
        }
    });
    
    // Start observing the document with the configured parameters
    observer.observe(document.body, { childList: true, subtree: true });
    }
  }, [])
  return (
    <div style={{margin: '64px 0'}}>
        <div data-oke-referrals-widget />
    </div>
  )
}

export default OkendoReferals