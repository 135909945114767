import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React, { useContext } from 'react'
import { CartContext } from '../../../context/shopContext'
import { trackRemoveFromtCart } from '../../../lib/ga4'
import Word from '../../Personalisation/font'
import {
  Container,
  Dec,
  Num,
  Inc,
  ItemContainer,
  ImageContainer,
  BodyContainer,
  TitleSpan,
  VarientSpan,
  ItemContainerTop
} from './LineItem.styles.js'; // This will automatically import from the index.js file in the same directory
import { persLookup } from '../../../templates/product-page'

const Personalise = ({ isLuggage, labelData }) => {
  const displaySize = {
    height: 25,
    width: 100,
    padding: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  }
  if (!labelData) return <></>
  return (
    <Word
      left={false}
      maxLetters={20}
      size={displaySize}
      mainCol={labelData.color}
      backCol={labelData.shadow}
      letters={labelData.text}
      fontSize={32}
      font={labelData.font}
    />
  )
}


const NumberCounter = ({
  number,
  incrementNumber,
  decrementNumber,
  customAttributes,
  noIncrement,
}) => {

  return (
    <Container style={{ justifyContent: 'center', fontWeight: 500 }}>
      <Dec onClick={() => decrementNumber(customAttributes, number)}> - </Dec>
      <Num> {number} </Num>
      {!noIncrement && (
        <Inc onClick={() => incrementNumber(customAttributes, number)}> + </Inc>
      )}
    </Container>
  )
}

const LineItem = ({ line_item, index, formatter }) => {
  const { incrementCartItem, decrementCartItem, storeLocale, currencyCode, setPersonalisingIndex, setCartOpen, stock, mixpanel } = useContext(CartContext)

  const sku = line_item?.globalProduct?.[storeLocale]?.sku
  const currentItemSKU = sku.endsWith('-P') ? sku.slice(0, -2) : sku;
  const localeVariant =
    line_item.globalProduct[storeLocale || localStorage.locale || 'en']

  let variantImage = localeVariant?.image ? (
    <GatsbyImage
      key={line_item.id}
      image={getImage(localeVariant?.image)}
      alt={`${line_item.title} product shot`}
      height="60px"
      style={{ aspectRatio: 1 }}
      backgroundColor='white'
      objectFit='contain'
    />
  ) : null

  const netPrice =
    (parseFloat(localeVariant?.price ? localeVariant.price : '').toFixed(2) ||
      0) * line_item.variantQuantity

  let totalDiscount = 0

  let discountsMap = {};

  if (line_item['discountAllocations'] && line_item['discountAllocations'].length > 0) {
    line_item['discountAllocations'].forEach((discountAllocation) => {
      // Determine the message (title or code) of the discount
      const message = discountAllocation['discountApplication'].title || discountAllocation['discountApplication'].code;
      const amount = parseFloat(discountAllocation['allocatedAmount']['amount']);

      // If the discount message already exists, sum the amounts, otherwise add a new entry
      if (discountsMap[message]) {
        discountsMap[message] += amount;
      } else {
        discountsMap[message] = amount;
      }

      // Update the total discount
      totalDiscount += amount;
    });
  }

  // Convert the discountsMap back into an array
  let discounts = Object.keys(discountsMap).map((message) => ({
    message: message,
    amount: discountsMap[message],
  }));

  const handleRemove = () => { }

  const increment = (customAttributes, variantQuantity) => {
    incrementCartItem({
      id: line_item.id,
      customAttributes,
      variantQuantity,
    })
  }

  const decrement = (customAttributes, variantQuantity) => {
    decrementCartItem({
      id: line_item.id,
      customAttributes,
      variantQuantity,
    })
    trackRemoveFromtCart({ items: { item_name: line_item.title, item_id: line_item.id, item_variant: line_item.variant, quantity: 1, price: localeVariant?.price }, currency: currencyCode, value: localeVariant?.price })
    mixpanel.track('Item Removed from Cart', {
      "Item ID": line_item.id.replace("gid://shopify/Product/", ""),
      "Item Name": line_item.title,
      "Item Type": line_item?.product_categories?.[0],
      "Price": netPrice,
      "Currency": currencyCode,
      "Quantity": 1,
    })
  }


  let title = line_item.title
  let showNumbers = line_item?.globalProduct[storeLocale]?.sku === 'OLY-BOT-SML02-NAV' ? false : true

  const attrPets = line_item?.customAttributes?.pets ? JSON.parse(atob(line_item.customAttributes.pets)) : []
  const names = attrPets.length > 0 ? `${attrPets[0].name}${attrPets[1] ? ` + ${attrPets[1].name}` : ''}` : undefined
  const petPos = line_item?.customAttributes?.position ? line_item?.customAttributes?.position : []

  return (
    <ItemContainer>
      <ImageContainer>{variantImage}
        <NumberCounter
          noIncrement={!showNumbers}
          number={line_item.variantQuantity}
          decrementNumber={decrement}
          customAttributes={line_item.customAttributes}
          incrementNumber={increment}
        />
      </ImageContainer>
      <BodyContainer>
        <ItemContainerTop>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <TitleSpan style={{ paddingRight: 5 }}> {title} </TitleSpan>
            <TitleSpan>
              {discounts.length > 0 ? (
                <>
                  <span style={{ fontWeight: 500 }}>
                    {formatter.format(
                      parseFloat(netPrice - totalDiscount).toFixed(2)
                    )}
                  </span>
                </>
              ) : (
                <span>{formatter.format(netPrice)}</span>
              )}
            </TitleSpan>
          </div>
        </ItemContainerTop>
        <ItemContainerTop>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              color: 'white'
            }}
          >

            <VarientSpan>
              {localeVariant?.title !== 'Default Title' &&
                localeVariant?.title
                  .replace('/ Default', '')
                  .replace('Default', '')}
            </VarientSpan>
          </div>
        </ItemContainerTop>
        {discounts.map((discount, key) => (
          <ItemContainerTop key={key} style={{ paddingTop: 0, marginTop: 0 }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <VarientSpan style={{ color: '#fff8' }}>
                {discount['message']}
              </VarientSpan>
              <VarientSpan>
                {discount['amount'] > 0 ? (
                  <>
                    <span style={{ color: '#fff8' }}>
                      {formatter.format(discount['amount'])}</span>
                  </>
                ) : (
                  <span />
                )}
              </VarientSpan>
            </div>
          </ItemContainerTop>
        ))}
        {
          (stock !== null && stock?.[storeLocale]?.[currentItemSKU]?.preorder) && (
            <ItemContainerTop style={{ paddingTop: 0, marginTop: 0 }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <VarientSpan style={{ color: '#CA7A4F' }}>
                  {stock?.[storeLocale]?.[currentItemSKU]?.oos_message}
                </VarientSpan>
              </div>
            </ItemContainerTop>
          )
        }
        {line_item.personalisedVariant && Object.keys(persLookup).includes(line_item?.globalProduct[storeLocale]?.sku) && (!line_item.product_categories?.includes('SLG')) && (
          <ItemContainerTop style={{ background: '#666666', margin: '16px 20px', justifyContent: 'center', display: 'flex', padding: 12, cursor: 'pointer' }}
            onClick={() => {
              setCartOpen(false);
              setPersonalisingIndex(index)
            }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                color: 'white',
                fontSize: '0.8em',
                userSelect: 'none',
                cursor: 'pointer',
              }}
            >
              <span>
                Add personalisation
              </span>
            </div>
          </ItemContainerTop>
        )}
        {line_item.customAttributes && (
          <ItemContainerTop style={{ background: '#666666', margin: '16px 20px', justifyContent: 'center', display: 'flex', padding: 12 }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <span>
                {line_item.customAttributes &&
                  Object.keys(line_item.customAttributes).length > 3 &&
                  line_item.customAttributes.text !== undefined && (
                    <>
                      <Personalise
                        isLuggage={
                          !['WKD', 'TOT', 'BPK', 'CAR'].includes(
                            localeVariant?.id?.slice(0, 3)
                          )
                        }
                        labelData={line_item.customAttributes}
                        color={localeVariant?.title}
                      />
                    </>
                  )}
                {line_item.customAttributes &&
                  Object.keys(line_item.customAttributes).length > 1 &&
                  line_item.customAttributes.pets !== undefined && (
                    <span style={{ 'display': 'flex', 'flexDirection': 'column', fontWeight: '400' }}>
                      <span style={{ fontSize: 10, fontWeight: '500' }}>
                        Your pet(s):
                      </span>
                      <span style={{ fontSize: 10 }}>
                        Name(s): {names}
                      </span>
                      <span style={{ fontSize: 10 }}>
                        Position: {petPos}
                      </span>
                    </span>
                  )}
              </span>
            </div>
          </ItemContainerTop>
        )}

        {/* <div>
          {oosData?.preorder === true && (
            <span
              style={{
                color: 'rgb(var(--terracota))',
                paddingLeft: 12,
                textTransform: 'uppercase',
                fontSize: 10,
                fontWeight: 600,
              }}
            >
              {oosData.oos_message}

            </span>
          )}
        </div> */}
      </BodyContainer>
    </ItemContainer>
  )
}

export default LineItem
