const auLookUp = {
  20: [
    ]
}
const usLookUp = { 
  20: [
    ]
}
const nzLookUp = {
  20: [
    ]
}
const ukLookUp = {
  10: [],
  15: []
}

const skusDiscounts = (locale) => {
  return Object.entries(locale).reduce((skuData, [amount, SKUs]) => {
    SKUs.forEach(SKU => {
      skuData[SKU] = { type: 'percentage', amount: parseInt(amount) };
    });
    return skuData;
  }, {});
};

const AU = skusDiscounts(auLookUp);
const US = skusDiscounts(usLookUp);
const NZ = skusDiscounts(nzLookUp);
const UK = skusDiscounts(ukLookUp);

export default { 'en-AU': {}, en: {}, 'en-NZ': {}, 'en-GB': {} }