import React, { useEffect, useState } from 'react'

import DownArrow from '../../images/up-arrow.svg'
import UpArrow from '../../images/down-arrow.svg'
import Link from '../Link'
import './DropdownSelect.css'

const Arrow = ({ open }) => {
  return (
    <img
      src={open ? DownArrow : UpArrow}
      style={{
        width: 12,
        height: 12,
        position: 'absolute',
        right: 32,
        top: 'calc(50% - 6px)',
      }}
      alt={'Arrow Image'}
    />
  )
}

const DropdownSelect = ({ optionsList, setValue, selected, id }) => {
  const [showOptionList, setShowOptionList] = useState(false)
  const [defaultSelectText, setDefaultSelectText] = useState(optionsList[selected])
  useEffect(() => {
    selected && setDefaultSelectText(optionsList[selected])
  }, [selected])
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  // This method handles the click that happens outside the
  // select text and list area
  const handleClickOutside = (e) => {
    if (
      !(
        e.target.classList.contains('custom-select-option') ||
        e.target.classList.contains('selected-text') ||
        e.target.classList.contains('selected-circle')
      )
    ) {
      setShowOptionList(false)
    }
  }

  // This method handles the display of option list
  const handleListDisplay = () => {
    setShowOptionList((prev) => !prev)
  }

  // This method handles the setting of name in select text area
  // and list display on selection
  const handleOptionClick = (e) => {
    const dataName = e.target.getAttribute('data-name')
    setDefaultSelectText(optionsList.find((val) => val.name === dataName))
    setValue(optionsList.find((val) => val.name === dataName).id)
    setShowOptionList(false)
  }
  return (
    <div className="custom-select-container">
      <div
        className={showOptionList ? 'selected-text active' : 'selected-text'}
        onClick={handleListDisplay}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            bottom: 0,
            width: 96,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 14,
            fontWeight: 500,
          }}
        >
          {defaultSelectText.secondary}
        </div>
        {defaultSelectText.name}
        <Arrow open={showOptionList} />
      </div>
      {showOptionList && (
        <ul className="select-options">
          {optionsList.map((option, key) => {
            return (
              <Link
                key={key}
                noLink={option.link === undefined ? true : false}
                to={option.link}
                style={{ color: '#000' }}
              >
                <div
                  className={`custom-select-option ${
                    defaultSelectText.id === option.id ? 'selected' : ''
                  }`}
                  data-name={option.name}
                  key={option.id}
                  onClick={handleOptionClick}
                >
                  <div
                    data-name={option.name}
                    className="selected-circle"
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      bottom: 0,
                      width: 96,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: 14,
                      fontWeight: 500,
                    }}
                    onClick={handleOptionClick}
                  >
                    {option.secondary}
                  </div>
                  {option.name}
                </div>
              </Link>
            )
          })}
        </ul>
      )}
    </div>
  )
}

export default DropdownSelect
