import React from 'react';
import queryString from 'query-string'
import { graphql, useStaticQuery } from 'gatsby'
import { createContext, useState, useEffect, useRef } from 'react'
import { createCheckout, updateCheckout, validateCheckout, applyDiscountCode } from '../lib/shopify'
import {
  sendShopifyAnalytics,
  getClientBrowserParameters,
  AnalyticsEventName,
  useShopifyCookies,
  ShopifySalesChannel,
  AnalyticsPageType
} from '@shopify/hydrogen-react';

import { suggestedLocales } from '../components/LocationSelector';
import useStockQuery from './useStockQuery';
import { useMixpanel } from '../hooks/useMixpanel';

const sendPageView = (analyticsPageData) => {
  const payload = {
    ...getClientBrowserParameters(),
    ...analyticsPageData,
  };
  sendShopifyAnalytics({
    eventName: AnalyticsEventName.PAGE_VIEW,
    shopifySalesChannel: ShopifySalesChannel.headless,
    payload,
  })
}

const sendAddToCart = (analyticsPageData) => {
  const payload = {
    ...getClientBrowserParameters(),
    ...analyticsPageData,
  };
  sendShopifyAnalytics({
    eventName: AnalyticsEventName.ADD_TO_CART,
    shopifySalesChannel: ShopifySalesChannel.headless,
    payload,
  })
}

const CartContext = createContext()

const sortObject = (object) => {
  return Object.keys(object)
    .sort()
    .reduce((obj, key) => {
      obj[key] = object[key]
      return obj
    }, {})
}

function delay(t) {
  return new Promise((resolve) => {
    setTimeout(resolve, t)
  })
}

const stockLookup = {
  'en-AU': 'https://july.com/stock/AU.json',
  'en-GB': 'https://july.com/stock/UK.json',
  'en-NZ': 'https://july.com/stock/AU.json',
  'en': 'https://july.com/stock/US.json',
  'en-CA': 'https://july.com/stock/US.json',
}

const currencyLookup = {
  'en-AU': 'AU$',
  'en-NZ': 'NZ$',
  'en-GB': 'GB£',
  'en': 'US$',
  'en-CA': 'CA$',
}

const currencyCodeLookup = {
  'en-AU': 'AUD',
  'en-NZ': 'NZD',
  'en-GB': 'GBP',
  'en': 'USD',
  'en-CA': 'CAD',
}

const currencyCodeWithPrefixLookUp = {
  'en-AU': 'AUD$',
  'en-NZ': 'NZD$',
  'en-GB': 'GBP£',
  'en': 'USD$',
  'en-CA': 'CAD$',
}

const currencyOnly = {
  'en-AU': '$',
  'en-NZ': '$',
  'en-GB': '£',
  'en': '$',
}

const analyticsShopData = {
  'en-AU': {
    shopId: 'gid://shopify/Shop/4716396613',
    currency: 'AUD',
    acceptedLanguage: 'en',
  },
  'en-NZ': {
    shopId: 'gid://shopify/Shop/71384990008',
    currency: 'NZD',
    acceptedLanguage: 'en',
  },
  'en': {
    shopId: 'gid://shopify/Shop/27817082983',
    currency: 'USD',
    acceptedLanguage: 'en',
  },
  'en-CA': {
    shopId: 'gid://shopify/Shop/58963984471',
    currency: 'USD',
    acceptedLanguage: 'en',
  },
  'en-GB': {
    shopId: 'gid://shopify/Shop/58215661756',
    currency: 'GBP',
    acceptedLanguage: 'en',
  }
}

const urlToLocale = (url) => {
  if (url.includes('/au')) {
    return 'en-AU'
  } else if (url.includes('/ca')) {
    return 'en-CA'
  } else if (url.includes('/nz')) {
    return 'en-NZ'
  } else if (url.includes('/uk')) {
    return 'en-GB'
  } else {
    return 'en'
  }
}
const findDiscount = (searchQuery) => {
  const search = queryString.parse(searchQuery)
  if (search.discount) {
    return search.discount
  } else {
    return null
  }
}

const getFreightData = () => {
  const data = useStaticQuery(graphql`
    query {
      AU: allAuShopifyProductVariant {
        nodes {
          sku
          title
          price
          shopifyId
        }
      }

      NZ: allNzShopifyProductVariant {
        nodes {
          sku
          title
          price
          shopifyId
        }
      }

      US: allUsShopifyProductVariant {
        nodes {
          sku
          title
          price
          shopifyId
        }
      }


      CA: allCaShopifyProductVariant {
        nodes {
          sku
          title
          price
          shopifyId
        }
      }

      UK: allUkShopifyProductVariant {
        nodes {
          sku
          title
          price
          shopifyId
        }
      }
    }
  `)
  return (locale, price) => {
    const min = '50'
    const max = '2000'

    const roundUp = (num) => {
      return Math.max(Math.min((Math.round(num / 50) + 1) * 50, max), min)
    }

    const getLookupString = `freight-${roundUp(price || 0)}`

    const { AU, NZ, US, UK, CA } = data
    const lookup = {
      'en-AU': AU.nodes.find(val => val.sku === getLookupString),
      'en-CA': CA.nodes.find(val => val.sku === getLookupString),
      'en-NZ': NZ.nodes.find(val => val.sku === getLookupString),
      'en-GB': UK.nodes.find(val => val.sku === getLookupString),
      'en': US.nodes.find(val => val.sku === getLookupString),
    }
    const localeVariants = lookup[locale]
    const findVariant = localeVariants

    if (findVariant == undefined || price === 0) {
      return []
    }

    return [
      {
        id: findVariant.shopifyId,
        variantQuantity: 1,
        customAttributes: undefined,
        price: findVariant.price
      }
    ]
  }
}

const localeMap = {
  us: 'en',
  au: 'en-AU',
  ca: 'en-CA',
  uk: 'en-GB',
  nz: 'en-NZ'
};

export default function ShopProvider({ location, children, pathname, ssrLocale }) {
  const localeKey = (pathname || location.pathname)?.split('/')[1];
  const locale = ssrLocale || localeMap[localeKey] || urlToLocale(location.pathname);
  const [storeLocale, setStoreLocale] = useState(locale)

  let cookieConsent = false
  if (typeof window !== `undefined`) {
    cookieConsent = window?.consented === true
  }
  useShopifyCookies({ hasUserConsent: (storeLocale !== 'en-GB' ? true : cookieConsent), domain: 'july.com' });
  const footerRef = useRef()
  const [pauseScroll, setPauseScroll] = useState(false)
  const [ipCountryCode, setIpCountryCode] = useState()

  const [personalisingIndex, setPersonalisingIndex] = useState()
  const [locationSelectorOpen, setLocationSelectorOpen] = useState(false)
  const [cart, setCart] = useState([])
  const [cartOpen, setCartOpen] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)
  const [checkoutId, setCheckoutId] = useState('')
  const [checkoutUrl, setCheckoutUrl] = useState('')
  const [cartLoading, setCartLoading] = useState(false)
  const [checkout, setCheckout] = useState({})
  const [discount, setDiscount] = useState(findDiscount(location.search))

  const [loaded, setLoaded] = useState(false)

  const [protectionEnabled, setProtectionEnabledState] = useState(true)
  const setProtectionEnabled = (val) => {
    localStorage.setItem('freight', val.toString())
    setProtectionEnabledState(val)
  }

  const mixpanel = useMixpanel()

  const addPersonalisationToItem = async (index, labelData) => {
    let updatedCart = cart
    const selectedItem = cart[index]
    selectedItem['globalProduct'] = selectedItem['personalisedVariant']
    selectedItem['id'] = `${selectedItem['id']}-P`
    selectedItem['personalisedVariant'] = undefined
    selectedItem['customAttributes'] = labelData

    setCartLoading(true)
    setCartOpen(true)
    setPersonalisingIndex()
    setCart(updatedCart)

    const newCheckout = await updateLocaleCheckout(checkoutId, updatedCart)
    if (newCheckout != null) {
      setCheckout(newCheckout)
      localStorage.setItem('checkout_id_v2', JSON.stringify([updatedCart, newCheckout]))
      setCartLoading(false)
    }
  }

  const updateCart = async () => {
    if (cart.length > 0) {
      setCartLoading(true)
      const newCheckout = await updateLocaleCheckout(
        checkoutId,
        cart
      )
      if (newCheckout != null) {
        setCheckout(newCheckout)
        localStorage.setItem('checkout_id_v2', JSON.stringify([cart, newCheckout]))
      }
      setCartLoading(false)
    }
  }

  const calculatePrice = (cart) => {
    return cart.reduce((acc, item) => {
      return acc + ((item.title !== 'Digital Gift Card' && item?.globalProduct[storeLocale]?.price * item?.variantQuantity) || 0)
    }, 0) || 0
  }

  const calculateGiftCard = (cart) => {
    return cart.reduce((acc, item) => {
      return acc + ((item.title === 'Digital Gift Card' && item?.globalProduct[storeLocale]?.price * item?.variantQuantity) || 0)
    }, 0) || 0
  }

  useEffect(() => {
    if (loaded) {
      updateCart()
    }
  }, [protectionEnabled])

  const useFreightData = getFreightData()

  const [fetchCartID, setFetchCartID] = useState()
  const fetchRef = useRef(fetchCartID)

  const [stock, setStock] = useStockQuery()

  const myRef = useRef({
    location: null,
  })
  useEffect(() => {
    // set the location on initial load
    if (!myRef.current.location) {
      myRef.current.location = location
      sendPageView({
        hasUserConsent: (storeLocale !== 'en-GB' ? true : window?.consented === true),
        ...analyticsShopData[storeLocale],
      });
    }
    // then make sure dialog is closed on route change
    else if (myRef.current.location !== location) {
      sendPageView({
        hasUserConsent: (storeLocale !== 'en-GB' ? true : window?.consented === true),
        ...analyticsShopData[storeLocale],
      });

      myRef.current.location = location
    }
  }, [location])

  if (cart === undefined) {
    setCart([])
  }

  useEffect(() => {
    if (menuOpen === true) {
      setCartOpen(false)
    }
    if (cartOpen === true) {
      setMenuOpen(false)
    }
  }, [cartOpen, menuOpen])

  const setDiscountCode = async (code) => {
    if (checkoutId && cart) {
      setCartLoading(true)
      await applyDiscountCode(checkoutId, code)
      setDiscount(code)

      const newCheckout = await updateLocaleCheckout(checkoutId, cart)
      if (newCheckout != null) {
        setCheckout(newCheckout)
        localStorage.setItem('checkout_id_v2', JSON.stringify([cart, newCheckout]))
        setCartLoading(false)
      }
    }
  }

  useEffect(() => {
    const initCart = async () => {
      if (localStorage.freight) {
        setProtectionEnabledState(localStorage.freight === 'true')
      }
      if (localStorage.checkout_id_v2) {
        const cartObject = JSON.parse(localStorage.checkout_id_v2)

        let loadedCart
        if (cartObject[0].id) {
          loadedCart = [cartObject[0]]
        } else if (cartObject[0].length > 0) {
          loadedCart = cartObject[0]
        } else {
          loadedCart = []
        }

        if (
          localStorage.locale !== locale &&
          locale !== undefined &&
          locale !== 'undefined'
        ) {
          localStorage.setItem('locale', locale)
          setStoreLocale(locale)
          clearCart()
        } else {
          let completedAt = undefined
          let failedValidation = false
          try {
            completedAt = await validateCheckout(cartObject[1].id)
          } catch (e) {
            failedValidation = true
            console.log(e)
          }
          if (completedAt) {
            clearCart()
          } else {
            let freshCart = loadedCart.map((val) => ({ ...val, id: val?.globalProduct[storeLocale]?.id })).filter(lineItem => lineItem.id !== undefined)
            if (JSON.stringify(freshCart) !== JSON.stringify(loadedCart) || failedValidation) {
              const freshCheckout = await createCheckout(
                [
                  ...freshCart,
                  ...(protectionEnabled && freshCart.length > 0) ? (
                    useFreightData(locale, calculatePrice(freshCart))
                  ) : ([])
                ],
                mixpanel.get_distinct_id()
              )
              setCart(freshCart)
              setCheckoutId(freshCheckout.id)
              setCheckoutUrl(freshCheckout.webUrl)
              setCheckout(freshCheckout)

              localStorage.setItem('checkout_id_v2', JSON.stringify([freshCart, freshCheckout]))
            } else {
              setCart(freshCart)
              setCheckoutId(cartObject[1].id)
              setCheckoutUrl(cartObject[1].webUrl)
              setCheckout(cartObject[1])
            }

          }
        }
      }
      setLoaded(true)
    }
    initCart()
  }, [])

  useEffect(() => {
    if (!checkout.lineItems) return;
    setCart((prev) => {
      const checkoutLineItems = checkout.lineItems.edges;

      let updatedCart = prev.map((cartItem) => {
        // Filter checkout line items that match the cart item
        const matchingCheckoutLineItems = checkoutLineItems.filter((checkoutLineItem) => {
          let customAttributes = {};
          checkoutLineItem.node.customAttributes.forEach(
            (attr) => (customAttributes[attr.key] = attr.value)
          );
          return (
            (checkoutLineItem.node.variant.id === cartItem.id || checkoutLineItem.node.variant.sku === cartItem.id) &&
            (!cartItem.customAttributes ||
              JSON.stringify(sortObject(cartItem.customAttributes || {})) ===
              JSON.stringify(sortObject(customAttributes)))
          );
        });

        // Sum the quantities and discount allocations for matching checkout line items
        const totalQuantity = matchingCheckoutLineItems.reduce((total, item) => total + item.node.quantity, 0);
        const totalDiscountAllocations = matchingCheckoutLineItems.reduce(
          (discounts, item) => [...discounts, ...item.node.discountAllocations],
          []
        );

        return {
          ...cartItem,
          quantity: totalQuantity,
          discountAllocations: totalDiscountAllocations,
        };
      });

      return updatedCart;
    });
  }, [checkout, discount]);


  useEffect(() => {

    const getOOS = async () => {
      try {
        let newStock = {
          'en-AU': null,
          'en-CA': null,
          'en-NZ': null,
          'en-GB': null,
          'en': null
        }

        let pullStockPromise = new Promise((res, rej) => {
          fetch(stockLookup[storeLocale], {
            headers: {
            },
          })
            .then((response) => {
              return response.json()
            })
            .then((data) => {
              return res(data)
            })
            .catch((e) => rej(e))
        }, [])
        newStock[storeLocale] = await pullStockPromise

        setStock(newStock)
      } catch (e) {
        console.log(e)
      }
    }
    getOOS()
    setInterval(getOOS, 1000 * 60 * 0.5)
  }, [storeLocale])

  const tryFetchCartUpdate = async (checkoutId, cart, cartId, max_retries = 3, current_try = 0) => {
    let resCheckout = await updateCheckout(checkoutId, cart, mixpanel.get_distinct_id())

    if (fetchRef.current !== cartId) {
      return null
    }
    if (!resCheckout && current_try < max_retries) {
      await delay(2000)
      resCheckout = await tryFetchCartUpdate(checkoutId, cart, cartId, max_retries, current_try + 1)
    }

    return resCheckout
  }

  const updateLocaleCheckout = async (checkoutId, newCart) => {
    const cartId = Date.now()

    setFetchCartID(cartId)
    fetchRef.current = cartId
    let cart = []
    let localeSpecificCart = newCart.map((lineitem) => ({
      id: lineitem?.globalProduct[storeLocale]?.id,
      variantQuantity: lineitem.variantQuantity,
      customAttributes: lineitem.customAttributes,
      globalProduct: lineitem.globalProduct
    })).filter(lineitem => lineitem.id !== undefined)

    cart = [
      ...localeSpecificCart,
      ...(
        (protectionEnabled && localeSpecificCart.length > 0) ? (
          useFreightData(locale, calculatePrice(localeSpecificCart))
        ) : ([])
      )
    ]
    return await tryFetchCartUpdate(checkoutId, cart, cartId, 3, 0)
  }

  async function addToCart(addedItem) {
    const productTracking = {
      "Item Id": addedItem.globalProduct?.[storeLocale]?.productId,
      "Price": addedItem.globalProduct?.[storeLocale]?.price,
      "Item Name": addedItem.title,
      "Item Type": addedItem.product_categories?.[0],
      "Currency": addedItem.currency,
      "Quantity": addedItem.variantQuantity,
      "Quick Add Selected": addedItem.quickSelected ? true : false
    };

    mixpanel.track('Item Added to Cart', productTracking)

    let newItem, listItems
    setCartOpen(true)
    setCartLoading(true)

    if (addedItem.constructor.name == 'Array') {
      listItems = addedItem.map((val) => ({ ...val }))
    } else {
      newItem = { ...addedItem }
      listItems = [newItem]
      try {
        document.dispatchEvent(new CustomEvent(
          'oke_connect_cart_itemVariantAdded', {
          detail: {
          productId: `${addedItem?.globalProduct[storeLocale]?.productId}`,
          variantId: addedItem.globalProduct[storeLocale]?.id?.replace('gid://shopify/ProductVariant/', ''),
          quantity: 1
          } }
        ))

        sendAddToCart({
          hasUserConsent: (locale !== 'en-GB' ? true : window?.consented === true),
          ...analyticsShopData[locale],
          pageType: AnalyticsPageType.product,
          resourceId: `gid://shopify/Product/${addedItem?.globalProduct[storeLocale]?.productId}`,
          cartID: checkoutId,
          products: [
            {
              quantity: 1,
              productGid: `gid://shopify/Product/${addedItem?.globalProduct[storeLocale]?.productId}`,
              variantGid: addedItem.globalProduct[storeLocale]?.id,
              name: addedItem.title,
              variantName: addedItem.variant,
              brand: 'July',
              price: addedItem.globalProduct[storeLocale]?.price,
              sku: addedItem.globalProduct[storeLocale]?.sku

            }
          ]
        })

      } catch (e) {
        console.log('Failed to send analytics of add to cart')
      }
    }



    if (cart.length === 0) {
      setCart([...listItems])

      localStorage.setItem('locale', locale)

      const createCart = listItems.map((val) => ({ ...val, id: val?.globalProduct[storeLocale]?.id })).filter(lineItem => lineItem.id !== undefined)
      let resCheckout = await createCheckout(
        [
          ...createCart,
          ...(
            (protectionEnabled && createCart.length > 0) ? (
              useFreightData(locale, calculatePrice(createCart))
            ) : ([])
          )
        ]
      )
      if (discount) {
        resCheckout = await applyDiscountCode(resCheckout.id, discount)
      }

      setCheckout(resCheckout)
      setCheckoutId(resCheckout.id)
      setCheckoutUrl(resCheckout.webUrl)
      setCartLoading(false)

      localStorage.setItem('checkout_id_v2', JSON.stringify([listItems, resCheckout]))
    } else {
      let newCart = [...cart]

      listItems.map((newMapItem) => {
        let added = false
        newCart.map((item, id) => {
          if (item.id === newMapItem.id || item.id === newMapItem?.globalProduct[storeLocale]?.id) {
            if (
              item.customAttributes &&
              newMapItem.customAttributes &&
              JSON.stringify(item.customAttributes) === JSON.stringify(newMapItem.customAttributes)
            ) {
              newCart[id].variantQuantity++
              added = true
            } else if (!item.customAttributes) {
              newCart[id].variantQuantity++
              added = true
            }
          }
        })
        if (!added) {
          newCart = [...newCart, newMapItem]
        }
      })

      setCart(newCart)
      const newCheckout = await updateLocaleCheckout(
        checkoutId,
        newCart.map((val) => ({ ...val, id: val?.globalProduct[storeLocale]?.id })).filter(lineItem => lineItem.id !== undefined)
      )
      if (newCheckout != null) {
        setCheckout(newCheckout)
        localStorage.setItem('checkout_id_v2', JSON.stringify([newCart, newCheckout]))
      }
      setCartLoading(false)
    }


  }

  async function removeCartItem(itemToRemove) {
    const updatedCart = cart.filter(
      (item) =>
        !(
          item.id === itemToRemove.id &&
          (!itemToRemove.customAttributes ||
            JSON.stringify(itemToRemove.customAttributes) === JSON.stringify(item.customAttributes))
        )
    )
    setCartLoading(true)

    setCart(updatedCart)
    if (updatedCart.length === 0) {
      setCartOpen(false)
    }

    const newCheckout = await updateLocaleCheckout(checkoutId, updatedCart)
    if (newCheckout != null) {
      setCheckout(newCheckout)
      localStorage.setItem('checkout_id_v2', JSON.stringify([updatedCart, newCheckout]))
      setCartLoading(false)
    }
  }

  async function incrementCartItem(item) {
    setCartLoading(true)
    sendAddToCart({
      hasUserConsent: (locale !== 'en-GB' ? true : window?.consented === true),
      ...analyticsShopData[locale],
    });

    let newCart = []
    cart.map((cartItem) => {
      if (
        cartItem.id === item.id &&
        ((!cartItem.customAttributes && !item.customAttributes) ||
          JSON.stringify(cartItem.customAttributes) == JSON.stringify(item.customAttributes))
      ) {
        cartItem.variantQuantity++
      }
      newCart.push(cartItem)
    })
    setCart(newCart)
    const newCheckout = await updateLocaleCheckout(checkoutId, newCart)
    if (newCheckout != null) {
      setCheckout(newCheckout)
      localStorage.setItem('checkout_id_v2', JSON.stringify([newCart, newCheckout]))
      setCartLoading(false)
    }
  }

  async function decrementCartItem(item) {
    setCartLoading(true)

    if (item.variantQuantity === 1) {
      await removeCartItem(item)
    } else {
      let newCart = []
      cart.map((cartItem) => {
        if (
          cartItem.id === item.id &&
          ((!cartItem.customAttributes && !item.customAttributes) ||
            JSON.stringify(cartItem.customAttributes) == JSON.stringify(item.customAttributes))
        ) {
          cartItem.variantQuantity--
        }
        newCart.push(cartItem)
      })

      setCart(newCart)
      const newCheckout = await updateLocaleCheckout(checkoutId, newCart)
      if (newCheckout != null) {
        setCheckout(newCheckout)
        localStorage.setItem('checkout_id_v2', JSON.stringify([newCart, newCheckout]))
      }
      setCartLoading(false)
    }
  }

  async function clearCart() {
    const updatedCart = []

    setCart(updatedCart)

    const newCheckout = await updateLocaleCheckout(checkoutId, updatedCart)
    if (newCheckout != null) {
      setCheckout(newCheckout)
      localStorage.setItem('checkout_id_v2', JSON.stringify([updatedCart, newCheckout]))
    }
  }

  const [collectionOpen, setCollectionOpen] = useState(false)

  useEffect(() => {
    const handleFetchIp = (e) => {
      const ipLoc = e.detail;
      const countryCode = ipLoc.response.countryCode;
      setIpCountryCode(countryCode);
      const hasInteracted = localStorage.getItem('locationSelectorInteracted') === 'true';

      if (!suggestedLocales[storeLocale].includes(countryCode) && !hasInteracted) {
        setLocationSelectorOpen(true);
        localStorage.setItem('locationSelectorInteracted', 'true');
      }
    };
    window.addEventListener('fetch-ip', handleFetchIp);

    return () => {
      window.removeEventListener('fetch-ip', handleFetchIp);
    };
  }, []);

  return (
    <CartContext.Provider
      value={{
        cart,
        cartOpen,
        setCartOpen,
        addToCart,
        checkoutUrl,
        removeCartItem,
        clearCart,
        cartLoading,
        incrementCartItem,
        decrementCartItem,
        checkout,
        storeLocale,
        menuOpen,
        setMenuOpen,
        stock,
        protectionEnabled,
        setProtectionEnabled,
        currencyPrefix: currencyLookup[storeLocale],
        currencyCode: currencyCodeLookup[storeLocale],
        currencyCodeWithPrefix: currencyCodeWithPrefixLookUp[storeLocale],
        currencyOnly: currencyOnly[storeLocale],
        calculatePrice: useFreightData(locale, calculatePrice(cart))[0]?.price,
        calculatedTotal: checkout?.lineItemsSubtotalPrice?.amount - calculateGiftCard(cart),
        setDiscountCode: setDiscountCode,
        setCollectionOpen,
        collectionOpen,
        footerRef,
        pauseScroll,
        setPauseScroll,
        personalisingIndex,
        setPersonalisingIndex,
        addPersonalisationToItem,
        locationSelectorOpen,
        setLocationSelectorOpen,
        ipCountryCode,
        mixpanel,
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

const ShopConsumer = CartContext.Consumer

export { ShopConsumer, CartContext }
