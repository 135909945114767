import React, { useState } from 'react';
import styled from '@emotion/styled';
import Modal from '../Modal';

import aus from '../Navigation/LocalisationSelector/flags/aus.svg'
import usa from '../Navigation/LocalisationSelector/flags/usa.svg'
import uk from '../Navigation/LocalisationSelector/flags/uk.svg'
import nz from '../Navigation/LocalisationSelector/flags/nz.svg'
import {Link} from 'gatsby';

export const suggestedLocales = {
  'en-CA': [
    'CA',
  ],
  'en': [
    'AI',
    'AG',
    'BS',
    'BB',
    'BZ',
    'BM',
    'KY',
    'CR',
    'CU',
    'DM',
    'DO',
    'SV',
    'GD',
    'GT',
    'HT',
    'HN',
    'JM',
    'MX',
    'MS',
    'NI',
    'PA',
    'PR',
    'KN',
    'LC',
    'VC',
    'TT',
    'TC',
    'US',
    'VG'
  ],
  'en-AU': [
    'AU',
    'FJ',
    'PF',
    'KI',
    'NR',
    'NC',
    'NU',
    'PG',
    'PN',
    'WS',
    'SB',
    'TL',
    'TK',
    'TO',
    'TV',
    'VU',
    'WF'],
  'en-GB': [
    'BD',
    'BW',
    'BI',
    'CM',
    'CY',
    'GM',
    'GH',
    'IN',
    'IE',
    'KE',
    'LS',
    'LR',
    'MW',
    'MY',
    'MV',
    'MT',
    'NG',
    'PK',
    'RW',
    'SC',
    'SL',
    'SG',
    'SO',
    'ZA',
    'SS',
    'LK',
    'SD',
    'SZ',
    'TZ',
    'UG',
    'GB',
    'ZM',
    'ZW'
  ],
  'en-NZ': [
    'NZ'
  ]
}

const localeData = {
  'en': {
    icon: usa,
    localeName: 'United States',
    link: '/us/'
  },
  'en-AU': {
    icon: aus,
    localeName: 'Australia',
    link: '/au/'
  },
  'en-NZ': {
    icon: nz,
    localeName: 'New Zealand',
    link: '/nz/'
  },
  'en-GB': {
    icon: uk,
    localeName: 'United Kingdom',
    link: '/uk/'
  }
}

const LocationLine = styled.a`
  display: flex;
  background-color: ${({selected}) => selected ? '#ccc' : '#fff'};
  justify-content: flex-start;
  align-items: center;
  padding: 16px 4px;
  min-width: calc(min(350px, 100vw - 48px - 48px - 32px));
`

const Heading = styled.div`
  font-size: 18px;
  line-height: 2.1;
  font-weight: 600;
`


const LocationSelector = ({
  locationSelectorOpen,
  setLocationSelectorOpen,
  currentCountryCode,
  storeLocale
}) => {
  const [selectedLocale, setSelectedLocale] = useState(storeLocale)
  const recomendedLocale = Object.keys(suggestedLocales).find(val => suggestedLocales[val].includes(currentCountryCode))
  return (
    <Modal
      show={locationSelectorOpen}
      onClose={() => setLocationSelectorOpen(false)}
    >
      <Heading>
        Select your country.
      </Heading>
      {
        Object.keys(localeData).map((locale, index) => {
          const selectedLocaleData = localeData[locale]
          return (
            <LocationLine href={selectedLocaleData.link} selected={selectedLocale === locale} key={index}>
              <img src={selectedLocaleData.icon} style={{width: 32, height: 32}} />
              <span style={{padding: '0 12px'}}> {selectedLocaleData.localeName} </span>
              {
                recomendedLocale === locale && (
                  <span style={{fontSize: '0.6em', color: '#888'}}> Recommended </span>
                )
              }
            </LocationLine>
          )
        })
      }
    </Modal>
  )
}

export default LocationSelector