import styled from '@emotion/styled'
import React, { useContext, useEffect, useState } from 'react'
import { CartContext } from '../../../context/shopContext'
import Link from '../../Link/index'
import { GatsbyImage, MainImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import { graphql, useStaticQuery } from 'gatsby'
import { css, keyframes } from '@emotion/react';
import FullWidthImage from '../../panels/FullWidthImage/full_width_image'

const ColorSelector = styled.div`
  width: 32px;
  height: 32px;
  background-color: ${(props) => props.color};
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  border-radius: 20px;
  margin: 0 12px;

  &:hover {
    border: 1px solid lightgrey;
  }
`;
const ColorSelectorContainer = styled.div`
display: flex;
justify-content: center;
margin: 16px 0;
@media (max-width: 1025px) {
  margin: 32px 0;
}
`
const SectionContainerTop = styled.div`
  position: relative;
  display: flex;
  align-items: stretch;
  flex-direction: row;

  @media (max-width: 1025px) {
    flex-direction: column;
  }
  @media (min-width: 1026px) {
    height: 90vh;
  }
  ol {
    margin: 0px;
    padding: 5%;
  }
  li {
    list-style: decimal;
  }
`
const SectionCopy = styled.div`
  width: ${(props) => props.width ? `${props.width}%` : '50%'};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (min-width: 1026px) {
    justify-content: space-between;
    min-width: 500px;
  }
  @media (max-width: 1025px) {
    width: 100%;
    text-align: left;
    z-index: 2;
    align-items: center;
    ${(props) => props.mobileHeight ? `${props.mobileHeight}vh;` : '50vh;'};
  }
`
const TopContainer = styled.div`
  @media (max-width: 1025px) {
    padding: 32px;
    justify-content: center;
    padding-bottom: 0;
  }
  @media (min-width: 1026px) {
    padding: 24px;
    padding-bottom: 0;
  }
`
const TopContainerMobile = styled.div`
@media (max-width: 1025px) {
  padding: 24px;
  justify-content: center;
  display: flex;
}
@media (min-width: 1026px) {
  display: none;
}
`
const ImageBanner = styled(GatsbyImage)`
  height: 80%;
  aspect-ratio: 1;
  max-width: 90%;
  max-height: 80vh;
  border-radius: 20px;
  @media (max-width: 1025px) {
    width: 70%;
    max-height: 245px;
  }
`
const SideImage = styled(GatsbyImage)`
  max-width: 45%;
  height: 35%;
  aspect-ratio: 1;
  margin: 20px;
  max-height: 80vh;
  @media (max-width: 1025px) {
    width: 70%;
    max-height: 245px;
  }
`
const Title = styled.div`
  display: flex;
  font-size: 72px;
  font-family: 'Roslindale';
  font-style: normal;
  font-weight: 200;
  font-stretch: normal;
  letter-spacing: -1.11px;
  color: #191919;
  padding: 24px 0;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-bottom: 0;
  @media (max-width: 1025px) {
    padding-bottom: 16px;
    padding-top: 0;
    font-size: 54px;
  }
  @media (max-width: 768px) {
    padding-bottom: 16px;
    padding-top: 0;
    justify-content: center;
    font-size: 45px;
    text-align: center;
  }
`
const Body = styled.div`
  color: #191919;
  font-size: 20px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: 0.71px;
  color: #000;
  justify-content: center;
  letter-spacing: -0.66px;
  display: flex;
  padding-bottom: 24px;
  text-align: center;
  padding: 0 10%;
  @media (max-width: 1025px) {
    padding: 0;
    font-size: 16px;
    text-align: center;
  }
`

const DetailedImagePanel = ({ data }) => {
  return (
    <>
      <TopContainer>
        {
          data?.Title && (
            <Title>{data?.Title}</Title>
          )
        }
        {
          data?.Copy && (
            <Body dangerouslySetInnerHTML={{ __html: data?.Copy }} />
          )
        }
      </TopContainer>
      <ColorSelectorContainer>
        {data?.ColorSelectorLink.map((color) => (
          <Link to={color.Link}>
            <ColorSelector
              color={color.Color}
              animate={true}
            />
          </Link>
        ))}
      </ColorSelectorContainer>
      {data?.FullWidthImage ? (
        <FullWidthImage data={data?.FullWidthImage} />
      ) : (
        <>
          <SectionContainerTop>
            <SectionCopy width={40} style={{ justifyContent: 'center', alignItems: 'end' }}>
              <SideImage
                alt={'bnsBanner Image'}
                objectFit='cover'
                image={withArtDirection(
                  getImage(data?.SideImage1?.file.childImageSharp?.gatsbyImageData),
                  [
                    {
                      media: '(max-width: 1024px)',
                      image: getImage(
                        data?.SideImage1?.file.childImageSharp?.gatsbyImageDataMobile ? data?.SideImage1?.file.childImageSharp?.gatsbyImageDataMobile : data?.SideImage1?.file.childImageSharp?.gatsbyImageDataMobile
                      ),
                    },
                  ]
                )}
              />
              <SideImage
                alt={'bnsBanner Image'}
                objectFit='cover'
                image={withArtDirection(
                  getImage(data?.SideImage2.file.childImageSharp?.gatsbyImageData),
                  [
                    {
                      media: '(max-width: 1024px)',
                      image: getImage(
                        data?.SideImage2.file.childImageSharp?.gatsbyImageDataMobile ? data?.SideImage2.file.childImageSharp?.gatsbyImageDataMobile : data?.SideImage2.file.childImageSharp?.gatsbyImageDataMobile
                      ),
                    },
                  ]
                )}
              />
            </SectionCopy>
            <SectionCopy width={60} style={{ justifyContent: 'center', alignItems: 'start' }}>
              <ImageBanner
                alt={'bnsBanner Image'}
                objectFit='cover'
                image={withArtDirection(
                  getImage(data?.MainImage.file.childImageSharp?.gatsbyImageData),
                  [
                    {
                      media: '(max-width: 1024px)',
                      image: getImage(
                        data?.MainImage.file.childImageSharp?.gatsbyImageDataMobile ? data?.MainImage.file.childImageSharp?.gatsbyImageDataMobile : data?.MainImage.file.childImageSharp?.gatsbyImageDataMobile
                      ),
                    },
                  ]
                )}
              />
            </SectionCopy>
          </SectionContainerTop>
        </>
      )}
    </>
  )
}

export default DetailedImagePanel
