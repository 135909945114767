import ShopProvider from './src/context/shopContext'
import { Location } from '@reach/router'
import mixpanel from 'mixpanel-browser'
import { MixpanelProvider } from './src/hooks/useMixpanel'
const React = require('react')

const mixpanelConfig = {
  apiToken: `${process.env.MIXPANEL_PRODUCT_TOKEN}`, // required
  // optional fields, default values
  enableOnDevMode: true, // if false mixpanel will be activated on NODE_ENV=production only
  // mixpanelConfig: null, // override specific config for mixpanel initialization https://github.com/mixpanel/mixpanel-js/blob/8b2e1f7b/src/mixpanel-core.js#L87-L110
  pageViews: 'all', // see below
    // set pageViews to 'all' and use this option to set the same event name for all page view events
  // trackPageViewsAs: null, // optionally: set an Event Name to use for all page views, eg: trackPageViewsAs: 'Page view'
  // getPageViewTransformerFn: null, // optionally: function body as a string to customize the event sent to mixpanel. Receives one parameter: location. Example 'return () => ({url: location.pathname})'
}

const api_host = process.env.MIXPANEL_API_BASE || 'https://api.mixpanel.com'

function isEnable(options) {
  return (
    (process.env.NODE_ENV === `production` || options.enableOnDevMode) &&
    options.apiToken
  )
}

function trackEvent(eventName, properties) {
  if (eventName) mixpanel.track(eventName, properties)
}

function getOptions(pluginOptions) {
  const defaultsOptions = {
    apiToken: null,
    enableOnDevMode: true,
    mixpanelConfig: null,
    trackPageViewsAs: null,
    getPageViewTransformerFn: 'return function(location) { return location; }'
  }

  const options = { ...defaultsOptions, ...pluginOptions }
  return { ...options, isEnable: isEnable(options) }
}

function trackPageViews(
  location,
  pageViews,
  trackPageViewsAs,
  getPageViewTransformerFn
) {
  if (pageViews && location) {
    let eventName
    if (pageViews instanceof Object) {
      eventName = pageViews[location.pathname]
    } else if (trackPageViewsAs) {
      eventName = trackPageViewsAs
    } else if (pageViews === 'all') {
      eventName = `View page ${location.pathname}`
    }

    const pageViewEventTransformerFn = new Function(
      'location',
      getPageViewTransformerFn
    )
    const event = pageViewEventTransformerFn()(location)
    trackEvent(eventName, event)
  }
}


export const wrapRootElement = (props) => {
  const { element } = props
  return (
    <MixpanelProvider>
      <Location>
        {({location}) => (
          <ShopProvider location={location} pathname={props.pathname}>
          {element}
          </ShopProvider>
        )}
      </Location>
    </MixpanelProvider>
  )
};

// gatsby-browser.tsx
export const onRouteUpdate = ({ location, prevLocation }) => {
  const options = getOptions(mixpanelConfig)

  if (isEnable(options)) {
    trackPageViews(
      location,
      options.pageViews,
      options.trackPageViewsAs,
      options.getPageViewTransformerFn
    )
  }

  if (!prevLocation || prevLocation?.pathname === location.pathname) {
    return;
  }

  if (!prevLocation && !location.hash) {
    // do not restore scroll position on page refresh without hash
    setTimeout(() => window.scrollTo({ top: 0, left: 0, behavior: 'instant' }), 0);
  }
}

export const shouldUpdateScroll = ({ routerProps: { location }, prevRouterProps, getSavedScrollPosition }) => {
  const prevLocation = prevRouterProps?.location

  window.history.scrollRestoration = 'manual'
  const currentPosition = getSavedScrollPosition(location) || [0, 0]

  setTimeout(() => {
    if (location.hash) {
      // If hash (`/#something`), smooth scroll to that position
      const item = document.querySelector(`${location.hash}`)
      item?.scrollIntoView({ behavior: 'smooth' })
    } else if (prevLocation?.pathname === location.pathname) {
    } else {
      // when using the browser back/forward buttons or navigating to a different page client-side, instant scroll to its last saved position
      window.scrollTo({
        top: currentPosition[1],
        left: currentPosition[0],
        behavior: 'instant',
      })

    }
  }, 0)

  // do not let gatsby do anything more
  return false
}

export const onClientEntry = () => {
  // Runs when the Gatsby browser runtime first starts
  setTimeout(() => {
    // Check if URL path matches the pattern /*/faqs/ and has #contact hash
    if (window.location.pathname.match(/\/.+\/faqs\//) && window.location.hash === '#contact') {
      // Extract the wildcard part from the URL
      const wildcard = window.location.pathname.split('/')[1];
      // Redirect while keeping the wildcard part in the new URL
      window.location.replace(`/${wildcard}/contact-us/`);
    }
  }, 0);

  const options = getOptions(mixpanelConfig)
  if (!options.isEnable) {
    mixpanel.init('disable', { autotrack: false, api_host })
    mixpanel.disable()
    return
  }

  const customOptions = Object.assign(
    { track_pageview: false, api_host },
    options.mixpanelConfig
  )

  mixpanel.init(options.apiToken, customOptions)
};
