import React, { useCallback, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import ArrowLeft from '../../../images/assets/arrow-left.svg'
import ArrowRight from '../../../images/assets/arrow-right.svg'
import ArrowLeftLight from '../../../images/assets/arrow-left-light.svg'
import ArrowRightLight from '../../../images/assets/arrow-right-light.svg'

const ArrowImage = styled.img`
  width: 14px;
  position: absolute;
  &:hover {
    transition: filter 0.3s ease;
    filter: opacity(0.6) !important;
  }

  &:active {
    transition: filter 0.3s ease;
    filter: opacity(0.1) !important;
  }
`

export const EmBlaCarouselArrowButtons = (emblaApi) => {
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true)
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true)

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollPrev()
  }, [emblaApi])

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollNext()
  }, [emblaApi])

  const onSelect = useCallback((emblaApi) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev())
    setNextBtnDisabled(!emblaApi.canScrollNext())
  }, [])

  useEffect(() => {
    if (!emblaApi) return

    onSelect(emblaApi)
    emblaApi.on('reInit', onSelect).on('select', onSelect)
  }, [emblaApi, onSelect])

  return {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  }
}

export const PrevButton = (props) => {
  const { children, className, ...restProps } = props

  return (
    <ArrowImage
      className={`slick-prev ${className}`}
      src={props?.buttonColor === 'light' ? ArrowLeftLight : ArrowLeft}
      {...restProps}
    />
  )
}

export const NextButton = (props) => {
  const { children, className, ...restProps } = props

  return (
    <ArrowImage
      className={`slick-next ${className}`}
      src={props?.buttonColor === 'light' ? ArrowRightLight : ArrowRight}
      {...restProps}
    />
  )
}
