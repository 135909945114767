function retryQuery(query, timout) {
  try {
    query()
  } catch (err) {
    setTimeout(() => retryQuery(query, timout), timout)
  }
}

const fbq = (b, c) => {
  if (typeof window !== 'undefined') {
    retryQuery(() => {
      window.fbq('trackSingle', '342655042978312', b, c)
    }, 3000)
  }
}
export default fbq