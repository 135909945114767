import styled from '@emotion/styled';
import { getImage, GatsbyImage, withArtDirection } from 'gatsby-plugin-image';
import Video from '../../panels/Video'

const TilesContainer = styled.div`
  padding: 2em 5rem;
  max-width: 1600px;
  margin: 0 auto;
  @media (max-width: 763px) {
    padding: 2em 16px;
  }
`

const Heading = styled.h4`
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  line-height: 4;
`

const Tiles = styled.div`
  display: flex;
  gap: 15px;
  overflow-x: scroll;  

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #111;
  }
  &&::-webkit-scrollbar-track-piece {
    background-color: #ededed;
  }
`

const Tile = styled.div`
  margin-bottom: 8px;
`

const MediaContainer = styled.div`
  width: 400px;
  min-width: 400px;
  max-width: 400px;
  height: 400px;
  aspect-ratio: 1;
  @media (max-width: 763px) {
    width: 200px;
    min-width: 200px;
    max-width: 200px;
    height: 200px;
  }
`

const TileImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%
`

const TileVideo = styled(Video)`
  width: 100%;
  height: 100%
`

const TileHeading = styled.h5`
  font-size: 12px;
  line-height: 1.8;
  font-weight: 500;
  margin-top: 8px;
`

const TileDescription = styled.h5`
  font-size: 12px;
`

// const data = {
//   Heading: 'OUR 5-STAR FUNCTIONS',
//   tiles: [
//     {
//       Image: 'https://beistravel.com/cdn/shop/files/Beis_Detail_Tote_Beige_0391_0012_Beis_Detail_Tote_Beige_0382-Recovered_0079_BEIS324597-TheMediumCheckInRoller-RomeLilac.jpg?v=1723071522&width=900',
//       Heading: 'RETRACTABLE BAG STRAP',
//       Body: 'Easy access to your essentials with a bag strap that can hold up to 15 lbs.'
//     },
//     {
//       Image: 'https://beistravel.com/cdn/shop/files/Beis_Detail_Tote_Beige_0391_0012_Beis_Detail_Tote_Beige_0382-Recovered_0079_BEIS324597-TheMediumCheckInRoller-RomeLilac.jpg?v=1723071522&width=900',
//       Heading: 'RETRACTABLE BAG STRAP',
//       Body: 'Easy access to your essentials with a bag strap that can hold up to 15 lbs.'
//     },
//     {
//       Image: 'https://beistravel.com/cdn/shop/files/Beis_Detail_Tote_Beige_0391_0012_Beis_Detail_Tote_Beige_0382-Recovered_0079_BEIS324597-TheMediumCheckInRoller-RomeLilac.jpg?v=1723071522&width=900',
//       Heading: 'RETRACTABLE BAG STRAP',
//       Body: 'Easy access to your essentials with a bag strap that can hold up to 15 lbs.'
//     },
//     {
//       Image: 'https://beistravel.com/cdn/shop/files/Beis_Detail_Tote_Beige_0391_0012_Beis_Detail_Tote_Beige_0382-Recovered_0079_BEIS324597-TheMediumCheckInRoller-RomeLilac.jpg?v=1723071522&width=900',
//       Heading: 'RETRACTABLE BAG STRAP',
//       Body: 'Easy access to your essentials with a bag strap that can hold up to 15 lbs.'
//     },
//     {
//       Image: 'https://beistravel.com/cdn/shop/files/Beis_Detail_Tote_Beige_0391_0012_Beis_Detail_Tote_Beige_0382-Recovered_0079_BEIS324597-TheMediumCheckInRoller-RomeLilac.jpg?v=1723071522&width=900',
//       Heading: 'RETRACTABLE BAG STRAP',
//       Body: 'Easy access to your essentials with a bag strap that can hold up to 15 lbs.'
//     },
//     {
//       Image: 'https://beistravel.com/cdn/shop/files/Beis_Detail_Tote_Beige_0391_0012_Beis_Detail_Tote_Beige_0382-Recovered_0079_BEIS324597-TheMediumCheckInRoller-RomeLilac.jpg?v=1723071522&width=900',
//       Heading: 'RETRACTABLE BAG STRAP',
//       Body: 'Easy access to your essentials with a bag strap that can hold up to 15 lbs.'
//     },

//   ]
// }

const ProductDetailTiles = ({data}) => {
  console.log(data)
  return ( 
    <TilesContainer>
      <Heading>
        {data?.Heading}
      </Heading>
      <Tiles>
        {
          data.Tiles?.map(tile => {
            return (
              <Tile>
                <MediaContainer>
                  { 
                    tile.Image[0] && (
                      tile?.Image[0]?.mime?.includes('video') || tile?.Image[0]?.mime?.includes('gif') ? (
                        <TileVideo
                          alt={tile.Image[0]?.alternativeText || tile.Image[0]?.name}
                          src={tile.Image[0]?.file?.publicURL}
                        />
                      ) : (
                        <TileImage
                          loading="lazy"
                          alt={tile.Image[0]?.alternativeText || tile.Image[0]?.name}
                          image={withArtDirection(getImage(tile.Image[0]?.file?.childImageSharp?.gatsbyImageData), [
                            {
                              media: '(max-width: 1024px)',
                              image: getImage(tile.Image[0]?.file?.childImageSharp?.gatsbyImageDataMobile),
                            },
                          ])}
                        />
                      )
                    )
                  }
                </MediaContainer>
                <TileHeading>{tile.Heading}</TileHeading>
                <TileDescription> {tile.Body} </TileDescription>
              </Tile>
    
            )
          })
        }
      </Tiles>
    </TilesContainer>
  );
}
 
export default ProductDetailTiles;