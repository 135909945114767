exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articles-js": () => import("./../../../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-olympics-giveaway-js": () => import("./../../../src/pages/olympics-giveaway.js" /* webpackChunkName: "component---src-pages-olympics-giveaway-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-tracking-js": () => import("./../../../src/pages/tracking.js" /* webpackChunkName: "component---src-pages-tracking-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/blog-page.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-dark-mode-dark-mode-js": () => import("./../../../src/templates/dark-mode/dark-mode.js" /* webpackChunkName: "component---src-templates-dark-mode-dark-mode-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-static-page-js": () => import("./../../../src/templates/static-page.js" /* webpackChunkName: "component---src-templates-static-page-js" */),
  "component---src-templates-trunk-index-js": () => import("./../../../src/templates/Trunk/index.js" /* webpackChunkName: "component---src-templates-trunk-index-js" */),
  "component---src-templates-uk-privacy-js": () => import("./../../../src/templates/uk/privacy.js" /* webpackChunkName: "component---src-templates-uk-privacy-js" */),
  "slice---src-components-cartv-2-upsell-module-js": () => import("./../../../src/components/Cartv2/UpsellModule.js" /* webpackChunkName: "slice---src-components-cartv-2-upsell-module-js" */),
  "slice---src-components-navigation-footer-js": () => import("./../../../src/components/Navigation/footer.js" /* webpackChunkName: "slice---src-components-navigation-footer-js" */),
  "slice---src-components-navigation-index-js": () => import("./../../../src/components/Navigation/index.js" /* webpackChunkName: "slice---src-components-navigation-index-js" */),
  "slice---src-components-navigation-mobile-menu-js": () => import("./../../../src/components/Navigation/mobile-menu.js" /* webpackChunkName: "slice---src-components-navigation-mobile-menu-js" */),
  "slice---src-components-panels-bundle-builder-bundle-builder-panel-js": () => import("./../../../src/components/panels/BundleBuilder/bundle_builder_panel.js" /* webpackChunkName: "slice---src-components-panels-bundle-builder-bundle-builder-panel-js" */),
  "slice---src-components-panels-detailed-shop-explore-detailed-shop-explore-js": () => import("./../../../src/components/panels/DetailedShopExplore/detailed-shop-explore.js" /* webpackChunkName: "slice---src-components-panels-detailed-shop-explore-detailed-shop-explore-js" */),
  "slice---src-components-panels-hover-shop-explore-hover-shop-explore-panel-js": () => import("./../../../src/components/panels/HoverShopExplore/hover-shop-explore-panel.js" /* webpackChunkName: "slice---src-components-panels-hover-shop-explore-hover-shop-explore-panel-js" */),
  "slice---src-components-panels-individual-discount-panel-individual-discount-panel-js": () => import("./../../../src/components/panels/IndividualDiscountPanel/individual-discount-panel.js" /* webpackChunkName: "slice---src-components-panels-individual-discount-panel-individual-discount-panel-js" */),
  "slice---src-components-panels-search-tiles-empty-slice-js": () => import("./../../../src/components/panels/SearchTiles/empty_slice.js" /* webpackChunkName: "slice---src-components-panels-search-tiles-empty-slice-js" */),
  "slice---src-components-panels-search-tiles-shop-panel-js": () => import("./../../../src/components/panels/SearchTiles/shop_panel.js" /* webpackChunkName: "slice---src-components-panels-search-tiles-shop-panel-js" */),
  "slice---src-components-panels-shop-panels-shop-panel-js": () => import("./../../../src/components/panels/ShopPanels/shop_panel.js" /* webpackChunkName: "slice---src-components-panels-shop-panels-shop-panel-js" */),
  "slice---src-components-panels-v-2-carosel-slice-js": () => import("./../../../src/components/panelsV2/Carosel/slice.js" /* webpackChunkName: "slice---src-components-panels-v-2-carosel-slice-js" */),
  "slice---src-components-panels-v-2-upsell-slice-js": () => import("./../../../src/components/panelsV2/Upsell/slice.js" /* webpackChunkName: "slice---src-components-panels-v-2-upsell-slice-js" */)
}

