import { navigate } from "gatsby"

const navigateLocale = (to, storeLocale) => {
  const languageLookup = {
    en: 'us',
    'en-AU': 'au',
    'en-NZ': 'nz',
    'en-GB': 'uk'
  }
  const language = languageLookup[storeLocale]
  let path = to

  const localizedPath = (`/${language}${
    path && (path.charAt(0) === '/' ? '' : '/')
  }${path}${
    path &&
    (path.slice(-1) !== '/' &&
    path.indexOf('?') === -1 &&
    path.indexOf('#') === -1
      ? '/'
      : '')
  }`).replace('//', '/')

  navigate(localizedPath)
}

export default navigateLocale
