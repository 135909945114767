import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'

const DetailsContainer = styled.div`
  ${(props) => (props.backgroundColor ? `background-color: ${props.backgroundColor};` : '#f6f3ef')};
  ${(props) => (props.title ? `padding-bottom: 80px;` : '')};
`
const DetailSectionsContaniner = styled.div`
  height: calc(100% - 200px);
  display: flex;
  justify-content: center;
  align-items: stretch;
  overflow: hidden;
  margin: 0 20px;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100vw;
  @media (max-width: 763px) {
    flex-wrap: wrap;
    height: auto;
  }
`
const DetailSectionImage = styled.img`
  width: 120px;
  margin: 0 35%%;
  height: auto;
  @media (max-width: 763px) {
    width: 90px;
    margin: 10% auto;
    height: auto;
  }
`
const DetailSectionImageContainer = styled.div`
  width: 100%;
  justify-content: center;
  @media (max-width: 763px) {
    width: 100%;
    margin: 0 auto;
  }
  @media (min-width: 762px) {
    display: flex;
    align-items: flex-end;
    margin: 25px;
    margin-bottom: 0;
  }
`
const DetailSectionContainer = styled.div`
  width: 15%;
  flex-direction: column;
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  @media (max-width: 763px) {
    padding: 0 5px;
    width: calc(50% - 16px);
  }
`
const SmallSubHeading = styled.div`
  font-size: 11px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 0.71px;
  color: rgb(var(--${(props) => props.color}));
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  @media (max-width: 1025px) {
    padding: 0;
  }
`

const DetailSection = ({ data }) => {
  return data.map((section, index) => {
    return (
      <DetailSectionContainer index={index} key={index}>
        <DetailSectionImageContainer>
          <DetailSectionImage src={section.image} alt={section.alt} />
        </DetailSectionImageContainer>
      </DetailSectionContainer>
    )
  })
}

const DetailsHeader = styled.h4`
  padding-top: ${(props) => (props.subHeading ? `0px` : '80px ')};
  ${(props) => (props.titleFont ? `font-family: ${props.titleFont};` : 'font-family: Objectivity;')};
  font-size: 42px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: -0.7px;
  text-align: center;
  padding-bottom: 40px;
  color: #000000;
`

const MultipleSVGs = ({ data }) => {
  return (
    <DetailsContainer backgroundColor={data.backgroundColor} title={data.Heading}>
      {
        data.Heading && (
          <>
            {data.SubHeading && (
              <SmallSubHeading color="black">{data.SubHeading}</SmallSubHeading>
            )}
            <DetailSectionsContaniner>
              <DetailsHeader titleFont={data.TitleFont} subHeading={data.SubHeading}>{data.Heading}</DetailsHeader>{' '}
            </DetailSectionsContaniner>
          </>
        )
      }
      <DetailSectionsContaniner>
        {data.SVGitem.map(({ svg, key }) => (
          <DetailSection
            data={[
              {
                image: svg?.file?.publicURL,
              },
            ]}
          />
        ))}
      </DetailSectionsContaniner>
    </DetailsContainer>
  )
}

export default MultipleSVGs
