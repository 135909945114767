import styled from '@emotion/styled';
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from 'react';
import DownArrow from '../../../images/chevron-down.svg';
import UpArrow from '../../../images/chevron-up.svg';
import DownArrowWhite from '../../../images/chevron-down-white.svg';
import UpArrowWhite from '../../../images/chevron-up-white.svg';

const SectionContainerTop = styled.div`
  background-color: ${(props) => props.color};
  position: relative;
  display: flex;
  align-items: stretch;
  flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
  background: ${(props) => (props.backgroundColor ? props.backgroundColor : '#F3ECE2;')};
  color: ${(props) => props.textColor ? props.textColor : '#000;'};
  @media (max-width: 768px) {
    ${(props) => (props.mobilePadding ? 'padding: 32px 0px;' : '')};
    flex-direction: ${(props) => (props.reverseMobile ? 'column-reverse' : 'column')};
  }
  @media (min-width: 1024px) {
    ${(props) => (props.minHeight ? `min-height: ${props.minHeight};` : '')}
    ${(props) => (props.negativeMargin ? 'height: calc(75vh - 126px);' : '')}
    ${(props) => (props.maxWidth ? 'max-width: 1200px; margin: 0 auto;' : '')}
  }

  ol {
    margin: 0px;
    padding: 5%;
  }
  li {
    list-style: decimal;
  }
`
const SectionCopy = styled.div`
  width: ${(props) => (props.short ? '45%;' : '50%;')};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 60px;
  
  background-color: rgb(var(--${(props) => props.color}));

  @media (min-width: 1026px) {
    ${({ school }) => school && `align-items: flex-start; text-align: left; padding-left: 10%;`}
    ${(props) => (props.textPosition === 'custom-top' ? `justify-content: start;` : 'justify-content: center;')}
  }
  @media (max-width: 1025px) {
    width: 100%;
    text-align: left;
    z-index: 2;
  }
  @media (max-width: 768px) {
    ${(props) => props.hide && 'display: none;'}
    padding: 40px;
    ${(props) => (props.index === 2 && 'padding-top: 0;')};

  }
`
const SectionTitleCopy = styled.div`
  /* width: 558px;
  height: 76px; */
  object-fit: contain;
  max-width: 350px;
  font-stretch: normal;
  letter-spacing: -1.39px;
  font-size: 34px;
  font-weight: 500;


  @media (max-width: 768px) {
    font-size: 32px;
    /* width: 261px;
    height: 55px; */
    margin: 0;
    letter-spacing: -0.24px;
    text-align: start;
    width: 100%;
  }
`
const TextContainer = styled.div`
  @media (max-width: 768px) {
    margin: 0;
  }
`
const DescriptionCopy = styled.div`
  /* width: 558px;
  height: 76px; */
  object-fit: contain;
  font-stretch: normal;
  letter-spacing: -0.25px;
  max-width: 350px;

  @media (max-width: 768px) {
    font-size: 15px;
    /* width: 261px;
    height: 55px; */
    margin: 0;
    text-align: start;
    width: 100%;
  }
`
const Container = styled.section`
  width: 100%;
  font-size: 16px;
`;
const AccordionItem = styled.div`
  color: ${(props) => props.textColor ? props.textColor : '#000;'};
  background-color: transparent;
  margin-bottom: 12px;
  ${({ small }) => small && 'margin-bottom: 6px;'}
  padding: 12px 0;
  border-bottom: ${(props) => props.textColor ? `1px solid ${props.textColor}` : '1px solid black;'}; 
`;
const TogglePanel = styled.div`
  ${({ expanded }) =>
    expanded &&
    `
    margin-bottom: 8px;
    `
  }
  cursor: pointer;
  display: flex;
  font-size: 20px;
`;
const Content = styled.div`
  font-weight: 500;
  flex: 1;
  ${({ boldHeadings }) => boldHeadings && 'font-weight: 600;'}
  ${({ small }) => small && 'font-size: 0.7em;'}
`;
const CollapsePanel = styled(motion.div)`
  & a {
    text-decoration: ${({ textDecoration }) => textDecoration === false ? 'none' : 'underline'};
  }
  ${({ small }) => small && 'font-size: 0.8em;'}
`;
const Icon = styled.img``;

const variants = {
  open: {
    height: "auto",
    opacity: 1,
  },
  collapsed: {
    height: 0,
    opacity: 0,
  },
};

const AccordionCollapse = ({ children, textDecoration, small }) => (
  <CollapsePanel
    initial="collapsed"
    animate="open"
    exit="collapsed"
    variants={variants}
    transition={{ duration: 0.15, ease: [0.04, 0.62, 0.23, 0.98] }}
    textDecoration={textDecoration}
    small={small}
  >
    {children}
  </CollapsePanel>
);

const AccordionToggle = ({
  id,
  children,
  toggle,
  isExpanded = false,
  boldHeadings,
  textColor,
  small
}) => {
  const handleClick = () => toggle && toggle(isExpanded ? "" : id);
  return (
    <TogglePanel onClick={handleClick} expanded={isExpanded}>
      <Content boldHeadings={boldHeadings} small={small}>{children}</Content>
      {isExpanded ? (
        <Icon src={textColor === '#ffffff' ? UpArrowWhite : UpArrow}></Icon>
      ) : (
        <Icon src={textColor === '#ffffff' ? DownArrowWhite : DownArrow}></Icon>
      )}
    </TogglePanel>
  );
};

const Accordion = ({ children, expanded = "", onChange, data, small }) => {
  return (
    <Container>
      {React.Children.map(children, ({ props: { children } }) => {
        const toggleElem = children[0];
        const collapseElem = children[1];

        const isExpanded = expanded === toggleElem.props.id;
        const toggleElemCloned = React.cloneElement(toggleElem, {
          toggle: (id) => onChange(id),
          isExpanded
        });

        return (
          <AccordionItem small={small} textColor={data.textColor}>
            {toggleElemCloned}
            <AnimatePresence initial={false}>
              {isExpanded && collapseElem}
            </AnimatePresence>
          </AccordionItem>
        );
      })}
    </Container>
  );
};

Accordion.Toggle = AccordionToggle;
Accordion.Collapse = AccordionCollapse;
Accordion.Item = ({ children, data }) => children;

export const AccordionBlock = ({ data, fullWidth, boldHeadings, textDecoration, small, initial = 0 }) => {
  const [active, setActive] = useState(initial);

  return (
    <Accordion small={small} onChange={(panel) => setActive(panel)} expanded={active} data={data}>
      {data.AccordionItem.map((item, index) => (
        <Accordion.Item key={index} data={data}>
          <Accordion.Toggle boldHeadings={boldHeadings} id={index} textColor={data?.textColor} small={small}>{item.AccordionTitle}</Accordion.Toggle>
          <Accordion.Collapse small={small} textDecoration={textDecoration}>
            {typeof item.AccordionCopy === 'string' || item.AccordionCopy instanceof String ? (
              <div dangerouslySetInnerHTML={{ __html: item.AccordionCopy }} />
            ) : (
              <div> {item.AccordionCopy} </div>
            )}
          </Accordion.Collapse>
        </Accordion.Item>
      ))}
    </Accordion>
  )
}

const USPFeatureDetails = ({ data }) => {
  return (
    <SectionContainerTop
      color={data.BackgroundColor}
      reverseMobile={data.MImageOn === 'Top'}
      reverse={data.DImageOn === 'Left'}
      HeightPercentage={data.HeightPercentage}
      hide={data.HideMediaOnMobile}
      backgroundColor={data.BackgroundColor}
      textColor={data.textColor}
    >
      <div id='usp-container'></div>
      <SectionCopy center={true} textPosition={data.Heading === "BUNDLE & SAVE" && 'custom-top'} hideCopy={data?.BodyAndCopyHide} index={1}>
        <TextContainer>
          <SectionTitleCopy>{data.Title}</SectionTitleCopy>
          <br />
          <DescriptionCopy>{data.Description}</DescriptionCopy>
        </TextContainer>
      </SectionCopy>
      <SectionCopy index={2}>
        <AccordionBlock
          fullWidth={true}
          data={data} />
      </SectionCopy>
    </SectionContainerTop>
  )
}

export default USPFeatureDetails
