import styled from '@emotion/styled';
import React, { useContext, useEffect, useState, useCallback, memo } from 'react';
import { CartContext } from '../../context/shopContext';
import fbq from '../../helpers/fbq';
import twq from '../../helpers/twq';
import Cart from '../Cartv2';
import { Slice } from 'gatsby';
import EmptyLayout from './empty';
import Div100vh from 'react-div-100vh';
import { persLookup } from '../../templates/product-page';
import LocationSelector from '../LocationSelector';
import PersonalisationModal from '../Personalisation'


export const MenuRight = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100%;
  background: white;
  transition: all 0.9s cubic-bezier(0.25, 1, 0.5, 1);
  z-index: 9999999999999999999;
  box-shadow: -3px 0 5px 0 #55555588;
  overflow: scroll;

  right: 0;
  width: 100vw;
  transform: translateX(${(props) => (props.isOpen ? '0' : 'calc(100vw + 5px)')});

  @media (min-width: 1025px) {
    transform: translateX(${(props) => (props.isOpen ? '0' : '555px')});
    width: 550px;
  }
`;

export const MenuLeft = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100%;
  background: white;
  transition: all 0.9s cubic-bezier(0.25, 1, 0.5, 1);
  z-index: 9999999;
  box-shadow: -3px 0 5px 0 #55555588;
  overflow: scroll;

  left: 0;
  width: 100vw;
  transform: translateX(${(props) => (props.isOpen ? '0' : 'calc(-100vw - 5px)')});

  @media (min-width: 1025px) {
    transform: translateX(${(props) => (props.isOpen ? '0' : '-555px')});
    width: 550px;
  }
`;

const OpaqueCover = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: #222222;
  transition: all 0.9s cubic-bezier(0.25, 1, 0.5, 1);
  z-index: 9999998;
  opacity: ${(props) => (props.isOpen ? '0.7' : '0')};
`;

const SideNav = memo(({ isOpen, close, children, side }) => {
  const MenuSlider = side === 'left' ? MenuLeft : MenuRight;
  return (
    <>
      {isOpen && <OpaqueCover onClick={close} isOpen={isOpen} />}
      <MenuSlider isOpen={isOpen}>
        <Div100vh style={{ overflow: 'hidden' }}>{children}</Div100vh>
      </MenuSlider>
    </>
  );
});

const ChildrenContainer = memo(({ children }) => (
  <div style={{ paddingTop: 0, background: 'white' }}>{children}</div>
));

const Layout = ({ children, pageContext, location }) => {
  const {
    cartOpen,
    setCartOpen,
    menuOpen,
    setMenuOpen,
    pauseScroll,
    personalisingIndex,
    setPersonalisingIndex,
    storeLocale,
    cart,
    addPersonalisationToItem,
    locationSelectorOpen,
    setLocationSelectorOpen,
    ipCountryCode,
  } = useContext(CartContext);
  const [pageViewed, setPageViewed] = useState(false);
  const personalising = personalisingIndex !== undefined;

  useEffect(() => {
    if (cartOpen || menuOpen || pauseScroll || personalising || locationSelectorOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [cartOpen, menuOpen, pauseScroll, personalising, locationSelectorOpen]);

  useEffect(() => {
    const fbids = localStorage?.getItem('fbid');
    setMenuOpen(false);

    if (!pageViewed) {
      fbq('PageView');
      twq('tw-oe3re-oe3st');
      setPageViewed(true);
    }

    if (['travel-bags', 'luggage', 'travel-accessories'].includes(location.pathname.replace('/au/', '').replace('/us/', '').split('/')[0])) {
      twq('tw-oe3re-oe3st');
      if (fbids != null) {
        if (typeof window !== 'undefined') {
          window.onload = function () {
            fbq('ViewContent', {
              content_type: 'product',
              content_ids: [fbids],
            });
          };
        }
      }
    }
  }, [location, pageViewed, setMenuOpen]);

  if (pageContext.layout === 'empty') {
    return <EmptyLayout>{children}</EmptyLayout>;
  }

  const handleSavePersonalisation = useCallback(
    (data) => {
      addPersonalisationToItem(personalisingIndex, data);
    },
    [addPersonalisationToItem, personalisingIndex]
  );

  const personalisingProduct = personalisingIndex !== undefined ? cart[personalisingIndex] : {};
  return (
    <div className="flex min-h-screen flex-col justify-between bg-white text-neutral-900">
      <div style={{ display: personalising ? 'block' : 'none', height: '100vh' }}>
        {personalising && (
          <div style={{ position: 'fixed', top: 0, left: 0, bottom: 0, right: 0, zIndex: 9999999999 }}>
            <PersonalisationModal
              handleSave={handleSavePersonalisation}
              handleClose={() => setPersonalisingIndex()}
              initialValues={{}}
              selectedCaseColor={persLookup[personalisingProduct?.globalProduct[storeLocale]?.sku]?.color_id}
              selectedCase={persLookup[personalisingProduct?.globalProduct[storeLocale]?.sku]?.product}
              timeframe={storeLocale === 'en-GB' ? '7' : storeLocale === 'en' ? '5-7' : '3-7'}
            />
          </div>
        )}
      </div>
      <LocationSelector
        locationSelectorOpen={locationSelectorOpen}
        setLocationSelectorOpen={setLocationSelectorOpen}
        currentCountryCode={ipCountryCode}
        storeLocale={storeLocale}
      />
      <SideNav isOpen={cartOpen} close={() => setCartOpen(false)}>
        <Cart pageContext={pageContext} />
      </SideNav>
      <Slice alias="navigation" pathname={location.pathname} navStyle={pageContext.navStyle}/>
      <ChildrenContainer>{children}</ChildrenContainer>
      <Slice alias="footer" />
    </div>
  );
};

export default memo(Layout);
